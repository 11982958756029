<template>
    <div class="solution">
        <div class="solutionTitle">解&nbsp;决&nbsp;方&nbsp;案</div>
        <div class="swipe" @click="toMonitoring">
            <div class="image">
                <img src="../assets/mobile/img/home/video.jpg" alt="" class="videoImg">
            </div>
            <div class="textcontent">
                <span class="title">视频传输应用 — 无线视频传输…</span><br>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Swiper',
    methods: {
        toMonitoring() {
            this.$router.push('mSolutionMonitoringMore')
        }
    }
}
</script>

<style scoped>
.solution {
    padding-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    min-height: 1800px;
    background-color: #eee;
    background: url(../assets/mobile/img/home/solution.png) center no-repeat;
    background-size: 365%;
}

.solution .solutionTitle {
    margin-bottom: 50px;
    text-align: center;
    color: #fff;
    font-size: 115px;
    font-weight: 700;
}

.solution .swipe {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 270px;
}

.solution .image .videoImg {
    width: 100%;
}

.solution .textcontent {
    padding: 100px;
}

.solution .textcontent .title {
    font-weight: 700;
    font-size: 90px;
}

.solution .textcontent .tit {
    font-size: 16px;
}
</style>
