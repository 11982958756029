<template>
  <div class="body">
    <!-- 主页banner Start -->
    <div class="banner">
      <m-top-bar></m-top-bar>
      <div class="content">
        <div class="company">
          <div class="title">宁&nbsp;讯&nbsp;科&nbsp;技</div>
          <div class="txt">专注无线智能产品及方案</div>
        </div>
        <div class="more" @click="toAbout">
          了解更多
        </div>
      </div>
    </div>
    <!-- 主页banner End -->

    <!-- 产品中心 Start -->
    <div class="product">
      <div class="productTitle">产&nbsp;品&nbsp;中&nbsp;心</div>
      <div class="box wirelessBox" @click="toProductIndustry">
        <img src="../../../assets/mobile/img/home/wireless.png" alt="" class="icon">
        <div class="title">工业无线覆盖传输系列</div>
        <span class="line"></span>
        <div class="txt">
          <div>吸顶式无线接入点</div>
          <div>无线接入点控制器</div>
          <div>工业级室外无线接入点</div>
        </div>
        <img src="../../../assets/mobile/img/home/add.png" alt="" class="add">
      </div>
      <div class="box cabinetBox" @click="toProductCabinet">
        <img src="../../../assets/mobile/img/home/cabinet.png" alt="" class="icon">
        <div class="title">智能无线保管柜系列</div>
        <span class="line"></span>
        <div class="txt">
          <div>智能无线保管柜说明</div>
          <div>设备模组介绍</div>
          <div>智能网关介绍</div>
        </div>
        <img src="../../../assets/mobile/img/home/add.png" alt="" class="add">
      </div>
      <div class="box platformBox" @click="toProductPlatform">
        <img src="../../../assets/mobile/img/home/platform.png" alt="" class="icon">
        <div class="title">无线网管平台软件系列</div>
        <span class="line"></span>
        <div class="txt">
          <div>云智能维护平台</div>
          <div>中间件平台</div>
          <div>数字孪生</div>
        </div>
        <img src="../../../assets/mobile/img/home/add.png" alt="" class="add">
      </div>
    </div>
    <!-- 产品中心 End -->

    <!-- 解决方案 Start -->
    <m-swiper></m-swiper>
    <!-- 解决方案 End -->

    <!-- 联系我们 Start -->
    <m-send-email></m-send-email>
    <!-- 联系我们 End -->

    <!-- 服务承诺 Start -->
    <div class="service">
      <div class="serviceTitle">服&nbsp;务&nbsp;承&nbsp;诺</div>
      <div class="text">
        公司秉承专业、周到、热情的经营理念及服务态度,在为用户提供的专业规划设计中，会充分注重营收策略，充分体现“以客户为主导”的思想。特别重视前期的数据搜集整理、同行探讨、网络设计、测试以及与用户充分的交流，最大限度地保障项目的成功实施，帮助用户快速、经济地实现无线网络传输、覆盖和用户接入及相关应用，并承诺相关:
      </div>
      <div class="title">
        * 提供客户的需求沟通及方案设计<br>
        * 提供客户的相关的样品借测<br>
        * 提供工程师客户到现场环境的勘察<br>
        * 提供客户产品技术安装培训<br>
        * 提供相关产品硬件三年质保
      </div>
    </div>
    <!-- 服务承诺 End -->

    <!-- 页脚区域 Start -->
    <m-base-bar></m-base-bar>
    <!-- 页脚区域 End -->
  </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'
import mSwiper from '@/components/mSwiper.vue';
import mSendEmail from '@/components/mSendEmail.vue';

export default {
  name: 'Home',
  components: {
    mTopBar,
    mBaseBar,
    mSwiper,
    mSendEmail,
  },
  methods: {
    toAbout() {
      this.$router.push('mAbout')
    },
    toProductIndustry() {
      this.$router.push('mProductIndustry')
    },
    toProductCabinet() {
      this.$router.push('mProductCabinet')
    },
    toProductPlatform() {
      this.$router.push('mProductPlatform')
    },
  }
}
</script>

<style scoped>
.body {
  background-color: #f7f7f7 !important;
}

/* 主页banner */
.banner {
  width: 100%;
  min-height: 2050px;
  background: url(../../../assets/mobile/img/home/banner.png) center no-repeat;
  background-size: 210%;
}

.banner .content {
  margin-top: 600px;
  margin-bottom: 50px;
}

.banner .company {
  margin-bottom: 80px;
  text-align: center;
  color: #fff;
}

.banner .company .title {
  font-weight: 700;
  font-size: 145px;
}

.banner .company .txt {
  font-size: 100px;
}

.banner .more {
  margin: 0 auto;
  border-radius: 20px;
  background-color: #c3a35d;
  width: 580px;
  height:200px;
  font-size: 70px;
  line-height: 220px;
  text-align: center;
  color: #fff;
}

/* 产品中心样式 */
.product {
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  min-height: 400px;
}

.product .productTitle {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 115px;
  font-weight: 700;
}

.product .box {
  padding: 50px;
  margin-bottom: 100px;
  width: 100%;
  min-height: 1000px;
  background-color: #f1f2f6 !important;
  box-shadow: 10px 20px 50px 1px rgb(32 32 32 / 30%);
}

.product .box .icon {
  margin-bottom: 25px;
  height: 150px;
}

.product .box .title {
  font-size: 92px;
  font-weight: 700;
}

.product .box .line {
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 10px;
  background-color: #c3a35d;
}

.product .box .txt {
  font-size: 82px;
}

.product .box .add {
  float: right;
  height: 150px;
}

/* 服务承诺样式 */
.service {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  min-height: 2000px;
  background: url(../../../assets/mobile/img/home/service.png) center no-repeat;
  background-size: 210%;
}

.service .serviceTitle {
  margin-bottom: 50px;
  text-align: center;
  color: #fff;
  font-size: 115px;
  font-weight: 700;
}

.service .text {
  text-indent: 2em;
  color: #fff;
  font-size: 82px;
  text-align: justify;
}

.service .title {
  margin-top: 50px;
  color: #fff;
  font-size: 82px;
}
</style>
  