<template>
    <div class="contact">
        <div class="tit">联&nbsp;系&nbsp;我&nbsp;们</div>
        <div class="sheet">
            <form id="fs-frm" name="complaint-form" accept-charset="utf-8" action="https://formspree.io/f/xlekgbkj"
                method="post">
                <fieldset id="fs-frm-inputs">
                    <!-- <label for="full-name">您的姓名</label> -->
                    <input type="text" name="name" id="full-name" placeholder="由此键入您的姓名" required="">
                    <!-- <label for="telephone">您的联系方式</label> -->
                    <input type="telephone" name="telephone" id="telephone" placeholder="由此键入您的电话">
                    <!-- <label for="email-address">您的邮箱</label> -->
                    <input type="email" name="_replyto" id="email-address" placeholder="由此键入您的邮箱" required="">
                    <!-- <label for="complaint">您的需求内容</label> -->
                    <textarea rows="6" name="complaint" id="complaint" placeholder="由此键入您的需求内容" required=""></textarea>
                    <input type="hidden" name="_subject" id="email-subject" value="Complaint Form Submission">
                </fieldset>
                <input type="submit" class="button" value="立&nbsp;即&nbsp;发&nbsp;送">
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sendEmail',
    props: {
        msg: String
    }
}
</script>

<style scoped>
.contact {
    padding-top: 50px;
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    min-height: 1800px;
}

.contact .tit {
    text-align: center;
    color: #333;
    font-size: 115px;
    font-weight: 700;
    margin-bottom: 50px;
}

.contact .sheet input {
    margin-bottom: 80px;
    width: 95%;
    height: 150px;
    font-size: 82px;
    padding-left: 50px;
}

.contact .sheet textarea {
    width: 93%;
    height: 500px;
    font-size: 82px;
    padding: 50px;
    resize: none;
}

.contact .sheet .button {
    margin-top: 80px;
    margin-bottom: 80px;
    width: 99%;
    height: 200px;
    background-color: #c3a35d;
    border: none;
    border-radius: 20px;
    color: #f5f5f5;
    font-size: 82px;
}
</style>
